<template>
  <div
    id="settings-proposal-invoice"
    class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav short"
  >
    <vue-headful
      title="Settings - Proposal & Invoice | Octoa"
    />
    <div class="flex flex-wrap mb-5">
      <div class="w-full lg:w-1/4">
        <setting-menu />
      </div>
      <div class="w-full lg:w-3/4">
        <div class="bg-white h-full mx-4 my-4 px-4 py-4 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded">
          <div
            v-show="isLoading"
            class="w-full px-2 text-center"
          >
            <img
              src="@/assets/img/icons/loader.svg"
              width="50"
              class="mt-32 text-center"
            >
          </div>
          <div v-show="!isLoading">
            <h2 class="font-bold mb-5">
              Proposal & Invoice
            </h2>

            <div class="flex items-center mb-5">
              <div class="w-1/2 md:w-2/6">
                Currency
              </div>
              <div>
                <div class="select-container">
                  <select v-model="selectedCurrency">
                    <option
                      v-for="currency in currencies"
                      :key="currency.id"
                      :value="currency.id"
                    >
                      {{ currency.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex items-center mb-5">
              <div class="w-3/5 md:w-2/6">
                Multiple currencies
                <v-help-tooltip
                  :options="currencyContent"
                />
              </div>
              <div>
                <v-checkbox
                  id="multiCurrencies"
                  v-model="multiCurrencies"
                  :checked="multiCurrencies > 0 ? true : false"
                  label=""
                  class=""
                />
              </div>
            </div>
            <hr class="mb-5">
            <div class="flex items-center mb-5">
              <div class="w-3/5 md:w-2/6">
                Proposal valid for
                <v-help-tooltip
                  :options="proposalContent"
                />
              </div>
              <div>
                <span class="pr-2"><input
                  v-model="proposalValidPeriod"
                  type="number"
                  min="0"
                  max="99"
                  class="text-right"
                ></span> days
              </div>
            </div>
            <div class="flex items-center mb-5">
              <div class="w-3/5 md:w-2/6">
                Invoice valid for
                <v-help-tooltip
                  :options="invoiceContent"
                />
              </div>
              <div>
                <span class="pr-2"><input
                  v-model="invoiceValidPeriod"
                  type="number"
                  min="0"
                  max="99"
                  class="text-right"
                ></span> days
              </div>
            </div>
            <div class="flex flex-wrap items-center mb-5">
              <div class="w-full md:w-2/6">
                Invoice / Proposal number prefix
              </div>
              <div class="mt-5 md:mt-0">
                <span class="pr-2"><input
                  v-model="numberPrefix"
                  type="text"
                  class="text-right w-24"
                  placeholder="eg. AA"
                ></span> <span class="mr-3">001</span>

                <span class="block md:inline mt-3 md:mt-0 text-grey-semi-light">Preview <span>{{ numberPrefix }}</span>001, <span>{{ numberPrefix }}</span>002, etc.</span>
              </div>
            </div>
            <div>
              <hr>
              <h2 class="font-bold mt-5 mb-5">
                Accept payment
              </h2>
              <div class="flex flex-wrap items-center mb-5">
                <div class="w-full md:w-2/6">
                  <img src="@/assets/img/icons/mollie-logo.svg" width="100" alt="Mollie integration" />
                </div>

                <div class="w-full mt-5 md:w-4/6">
                  <p class="mb-5 leading-normal">
                    <strong>Pricing</strong><br>
                    See: <a
                      href="https://www.mollie.com/en/pricing"
                      target="_blank">https://www.mollie.com/en/pricing</a><br><br>
                    <strong>How to activate payment method</strong><br>
                    See: <a
                      href="https://help.mollie.com/hc/en-us/articles/115000648269-How-do-I-activate-a-payment-method"
                      target="_blank">https://help.mollie.com/hc/en-us/articles/115000648269-How-do-I-activate-a-payment-method</a>

                    <div v-if="!isMollieVerified">
                      <div class="my-5 rounded py-4 px-4 leading-loosen" style="background-color: #FFF2CD">
                        Your Mollie account is not verified. Please verify your account to start accepting payments.
                      </div>
                    </div>
                  </p>
                  <div v-if="this.mollieConnectToken === ''">
                    <a :href="mollieRedirectLink">
                      <button
                        class="button btn__content-text"
                      >
                        Connect
                      </button>
                    </a>
                  </div>
                  <div v-else>
                    <button
                      class="button btn__content-text"
                      @click="disconnectMollie"
                    >
                      Disconnect
                    </button>
                  </div>
                </div>
              </div>
              <hr>
              <div class="flex flex-wrap items-center mb-5">
                <div class="w-full md:w-2/6">
                  Stripe integration
                </div>

                <div class="w-full mt-5 md:w-4/6">
                  <p class="mb-5 leading-normal">
                    <strong>Credit cards</strong><br>
                    European cards: 1.5% + €0.25 per transaction<br>
                    Non-European cards: 3.25% + €0.25 per transaction<br><br>
                    <strong>iDeal payment (available in Euro only)</strong><br>
                    €0.29 per transaction<br><br>
                    <strong>Bancontact payment (available in Euro only)</strong><br>
                    €0.35 per transaction (in BE and NL, for pricing in other countries see
                    <a
                      href="https://stripe.com/pricing"
                      target="_blank"
                    >https://stripe.com/pricing</a>)<br><br>
                    <strong>giropay payment (available in Euro only)</strong><br>
                    1.4% + €0.25 per transaction<br><br>
                    - Transaction fees charged by Stripe <br>
                    - Paid to your bank account up to 7 business days <br>
                    - No setup, monthly or card storage fees, no minimum charges, or penalties for failed payments <br>
                  </p>
                  <div v-if="stripeAccountId == ''">
                    <a :href="stripeRedirectLink">
                      <img
                        src="@/assets/img/icons/ico-connect-stripe.png"
                        width="180"
                      >
                    </a>
                  </div>
                  <div v-else>
                    <button
                      class="button btn__content-text"
                      @click="disconnectStripe"
                    >
                      Disconnect
                    </button>
                    <div class="my-5">
                      <v-checkbox
                        id="stripeAcceptCreditcard"
                        v-model="stripeAcceptCreditcard"
                        :checked="stripeAcceptCreditcard"
                        label="Accept credit card"
                      />
                    </div>
                    <div>
                      <v-checkbox
                        id="stripeAcceptIdeal"
                        v-model="stripeAcceptIdeal"
                        :checked="stripeAcceptIdeal"
                        label="Accept iDeal"
                        @click="alertIdeal"
                      />
                    </div>
                    <br>
                    <div>
                      <v-checkbox
                        id="stripeAcceptBancontact"
                        v-model="stripeAcceptBancontact"
                        :checked="stripeAcceptBancontact"
                        label="Accept Bancontact"
                        @click="alertBancontact"
                      />
                    </div>
                    <br>
                    <div>
                      <v-checkbox
                        id="stripeAcceptGiropay"
                        v-model="stripeAcceptGiropay"
                        :checked="stripeAcceptGiropay"
                        label="Accept giropay"
                        @click="alertGiropay"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <h2 class="font-bold mt-5 mb-3">
              Payment information
            </h2>
            <p class="text-sm">
              Edit the information that is shown at the top of a proposal and invoice
            </p>
            <div>
              <div class="demo-container pt-5 px-5 mt-8 rounded">
                <div class="demo-header flex">
                  <div class="hidden md:block w-5/12 text-left">
                    <div
                      class="logo"
                    >
                      <img
                        src="@/assets/img/logo-square.png"
                        width="100"
                      >
                    </div>
                    <div class="mt-10 flex items-center ">
                      <div class="w-4/12 mr-2 text-grey-semi-light">
                        Quote
                      </div>
                      <div class="w-8/12 font-bold">
                        001
                      </div>
                    </div>
                    <div class="my-3 flex items-center ">
                      <div class="w-4/12 mr-2 text-grey-semi-light">
                        Issued
                      </div>
                      <div class="w-8/12 ">
                        {{ today }}
                      </div>
                    </div>
                    <div class="my-3 flex items-center ">
                      <div class="w-4/12 mr-2 text-grey-semi-light">
                        Valid until
                      </div>
                      <div class="w-8/12 ">
                        {{ next2Week }}
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-7/12 flex flex-wrap text-left">
                    <div class="hidden md:flex w-full leading-loosen">
                      <div class="w-4/12 text-grey-semi-light">
                        <div class="ml-8">
                          From
                        </div>
                      </div>
                      <div class="w-8/12 text-grey-darker">
                        <div class="ml-4">
                          <strong class="text-black">{{ user.company.name }}</strong> <br>
                          {{ user.company.address }} <br>
                          <pre
                            v-if="user.company.city"
                            style="font-family:inherit"
                          >{{ user.company.city }}<span v-if="user.company.postal_code">,</span> {{ user.company.postal_code }}</pre>
                          {{ user.company.country }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex flex-wrap md:my-4 w-full leading-loosen"
                    >
                      <div class="w-full mb-3 md:mb-0 md:w-4/12 text-grey-semi-light">
                        <span class="hidden md:inline">&nbsp;</span>
                        <div
                          class="floating-tag"
                        >
                          <div class="tip" />
                          Add extra information e.g. company id and bank details
                        </div>
                      </div>
                      <div class="w-full md:w-8/12 text-grey-darker">
                        <div class="block md:hidden mb-3">
                          <strong class="text-black">{{ user.company.name }}</strong> <br>
                          {{ user.company.address }} <br>
                          <pre
                            v-if="user.company.city"
                            style="font-family:inherit"
                          >{{ user.company.city }}<span v-if="user.company.postal_code">,</span> {{ user.company.postal_code }}</pre>
                          {{ user.company.country }}
                        </div>
                        <textarea
                          v-model="paymentInfo"
                          rows="5"
                        />
                      </div>
                    </div>
                    <div class="hidden md:flex w-full leading-loosen">
                      <div class="w-4/12 text-grey-semi-light">
                        <div class="ml-8">
                          To
                        </div>
                      </div>
                      <div class="w-8/12 text-grey-darker">
                        <div class="ml-4">
                          Christine McKinsey <br>
                          Jansweg 32 <br>
                          Haarlem, 2011RN <br>
                          Netherlands
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <h2 class="font-bold mt-5 mb-3">
              Invoice footer
            </h2>
            <p class="text-sm">
              Edit the information that is shown at the bottom of an invoice
            </p>
            <div class="mt-5 bg-grey-f9 px-5 py-5 rounded">
              <textarea
                v-model="invoiceFooter"
                rows="5"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="!isLoading"
        class="w-full px-4 mt-8 lg:px-0 lg:mt-4 mb-20 text-right"
      >
        <v-button @click="save">
          Save
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth';
import {addDays, format} from 'date-fns';
import {logException} from '@/helpers';

export default {
    name: 'SettingsInvoicing',
    data(){
      return {
        today: new Date(),
        next2Week: new Date(),
        isLoading: true,
        invoiceSettings: {},
        isSaving: false,
        isMollieVerified: true,
        currencies: {},
        selectedCurrency: null,
        multiCurrencies: 0,
        invoiceValidPeriod: 0,
        proposalValidPeriod: 0,
        numberPrefix: '',
        invoiceFooter: '',
        user: auth.user(),
        currencyContent: {
          content: 'Allow to use a different currency per project. Any proposals / invoices within the project will use that specific currency.'
        },
        invoiceContent: {
          content: 'This will automatically creates a due date based on your invoice date.'
        },
        proposalContent: {
          content: 'The period that your proposals are valid after creating them.'
        },
        stripeContent:{
          content: 'Your client can pay your invoice using credit card or iDeal through Stripe.',
        },
        mollieContent:{
          content: 'Your client can pay your invoice using credit card or iDeal through Mollie.',
        },
        mollieAccountId: '',
        mollieConnectToken: '',
        stripeAcceptIdeal: false,
        stripeAcceptBancontact: false,
        stripeAcceptGiropay: false,
        stripeAcceptCreditcard: false,
        stripeRedirectLink: '',
        paymentInfo: '',
      }
    },
   async mounted(){

      this.today = format(new Date(), 'MMM D, YYYY')
      this.next2Week = format(addDays(new Date(), 14), 'MMM D, YYYY')

      try {
        const { data } = await this.$api.get('currency').list()
        this.currencies = data.currencies
      } catch(e){
        this.$toasted.global.api_error(e)
        logException(e)
      }

      if(this.$route.query.code){
        try {
          const { data } = await this.$api.get('stripe').link(this.user.company.id, { companyId: this.user.company.id, code: this.$route.query.code })
          this.stripeAccountId = data.stripeAccountId
        } catch(e){
          this.$toasted.global.general_error({
            message : 'Stripe connection error.'
          })
          this.$router.push('/settings/invoicing')
        }
      }

      // let clientId = 'ca_I1yH85yOyzTkDp5GjX83EQa9FUbETP6E'
      let clientId = 'ca_P6M0xcsWccruDXIbwAGq5Yr52FmogZlw'

      if(window.location.hostname == 'app.octoa.com'){
        clientId = 'ca_I1yH71FoSqKSXMK1zWRFqLbEvzIGhhQJ'
      }

      this.stripeRedirectLink = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${window.location.href}`

      let mollieRedirectLink =  await this.$api.get('mollie').getAuthLink()
      this.mollieRedirectLink =  mollieRedirectLink.data.url

      await this.refresh()

      if (this.mollieAccountId !== '') {
        const profiles = await this.$api.get('mollie').getProfiles()

        if (profiles && profiles.data._embedded && profiles.data._embedded.profiles && profiles.data._embedded.profiles.length > 0) {
          const firstProfileStatus = profiles.data._embedded.profiles[0].status

          if (firstProfileStatus !== 'verified') {
            this.isMollieVerified = false
          }
        }
      }
      this.isLoading = false
    },
    methods: {
      async alertGiropay(){

        this.$toasted.clear()
        if(!this.stripeAcceptGiropay){
          const wrapper = document.createElement('div')
          wrapper.innerHTML = '<p>Please make sure you have giropay payment method activated in your Stripe account: <a class="mt-1 block text-green underline" href="https://dashboard.stripe.com/settings/payment_methods" target="_blank">https://dashboard.stripe.com/settings/payment_methods</a>'

          const goStripe = await this.$alert.alert({
            title: 'Activate giropay',
            content: wrapper,
            button: 'Check',
          })

          if(goStripe){
            try {
              const { data } = await this.$api.get('stripe').giropay(this.user.company.id,  { companyId: this.user.company.id, stripeAccountId: this.stripeAccountId })
              this.stripeAcceptGiropay = true
            } catch(e){
              this.stripeAcceptGiropay = false
              this.$toasted.global.general_error({
                message : 'Activate giropay inside your Stripe account and try again.'
              })
            }
          }
        }

      },
      async alertBancontact(){

        this.$toasted.clear()
        if(!this.stripeAcceptBancontact){
          const wrapper = document.createElement('div')
          wrapper.innerHTML = '<p>Please make sure you have Bancontact payment method activated in your Stripe account: <a class="mt-1 block text-green underline" href="https://dashboard.stripe.com/settings/payment_methods" target="_blank">https://dashboard.stripe.com/settings/payment_methods</a>'

          const goStripe = await this.$alert.alert({
            title: 'Activate Bancontact',
            content: wrapper,
            button: 'Check',
          })

          if(goStripe){
            try {
              const { data } = await this.$api.get('stripe').bancontact(this.user.company.id,  { companyId: this.user.company.id, stripeAccountId: this.stripeAccountId })
              this.stripeAcceptBancontact = true
            } catch(e){
              this.stripeAcceptBancontact = false
              this.$toasted.global.general_error({
                message : 'Activate Bancontact inside your Stripe account and try again.'
              })
            }
          }
        }

      },
      async alertIdeal(){

        this.$toasted.clear()
        if(!this.stripeAcceptIdeal){
          const wrapper = document.createElement('div')
          wrapper.innerHTML = '<p>Please make sure you have iDeal payment method activated in your Stripe account: <a class="mt-1 block text-green underline" href="https://dashboard.stripe.com/settings/payment_methods" target="_blank">https://dashboard.stripe.com/settings/payment_methods</a>'

          const goStripe = await this.$alert.alert({
            title: 'Activate iDeal',
            content: wrapper,
            button: 'Check',
          })

          if(goStripe){
            try {
              const { data } = await this.$api.get('stripe').ideal(this.user.company.id,  { companyId: this.user.company.id, stripeAccountId: this.stripeAccountId })
              this.stripeAcceptIdeal = true
            } catch(e){
              this.stripeAcceptIdeal = false
              this.$toasted.global.general_error({
                message : 'Activate iDeal inside your Stripe account and try again.'
              })
            }
          }
        }

      },
      async disconnectMollie(){
        try {
          await this.$api.get('mollie').disconnect({ companyId: this.user.company.id })
          await this.refresh()
        } catch(e){
          this.$toasted.global.general_error({
            message : 'Mollie connection error.'
          })
        }
      },
      async disconnectStripe(){
        try {
          const { data } = await this.$api.get('stripe').disconnect(this.user.company.id,  { companyId: this.user.company.id })
          await this.refresh()
        } catch(e){
          this.$toasted.global.general_error({
            message : 'Stripe connection error.'
          })
        }
      },
      save(){

        this.$toasted.clear()
        this.$validator.validate().then(result => {
          if(result){

            if(this.stripeAccountId != '' && !this.stripeAcceptIdeal && !this.stripeAcceptCreditcard && !this.stripeAcceptBancontact && !this.stripeAcceptGiropay){
              this.$toasted.global.general_error({
                message : 'You need to select at least one payment method.'
              })
              return false
            }
            this.doSave()
          }
        })
      },
      async doSave(){
        this.saving = true

        const postData = {
          selectedCurrency: this.selectedCurrency,
          invoiceValidPeriod: this.invoiceValidPeriod,
          proposalValidPeriod: this.proposalValidPeriod,
          multiCurrencies: this.multiCurrencies,
          numberPrefix: this.numberPrefix,
          invoiceFooter: this.invoiceFooter,
          stripeAccountId: this.stripeAccountId,
          stripeAcceptIdeal: this.stripeAcceptIdeal,
          stripeAcceptBancontact: this.stripeAcceptBancontact,
          stripeAcceptGiropay: this.stripeAcceptGiropay,
          stripeAcceptCreditcard: this.stripeAcceptCreditcard,
          paymentInfo: this.paymentInfo,
        }

        try {
          await this.$store.dispatch('company/patchInvoice', postData)
          this.$toasted.global.general_success({
            message : 'Company invoice settings updated.'
          })
        } catch(e) {
          this.$toasted.global.api_error(e)
        }

        this.saving = false
        await this.refresh()
      },
      async refresh() {
        try {
          await this.$store.dispatch('company/refreshCompanyDetails')

          const company = this.$store.getters['company/details']
          this.selectedCurrency = company.currency.id
          this.multiCurrencies = (company.multiCurrencies) ? Number(company.multiCurrencies) : 0
          this.invoiceValidPeriod = company.invoiceValidPeriod
          this.proposalValidPeriod = company.proposalValidPeriod
          this.numberPrefix = company.numberPrefix
          this.stripeAccountId = company.stripeAccountId
          this.mollieConnectToken = company.mollieConnectToken
          this.invoiceFooter = company.invoiceFooter
          this.paymentInfo = company.paymentInfo

          if(company.stripeAccountId){
            for (const method of company.stripePaymentMethods) {
              if(method == 'card'){
                this.stripeAcceptCreditcard = true
              }
              if(method == 'ideal'){
                this.stripeAcceptIdeal = true
              }
              if(method == 'bancontact'){
                this.stripeAcceptBancontact = true
              }
              if(method == 'giropay'){
                this.stripeAcceptGiropay = true
              }
            }
          }


          auth.refreshUser()

        } catch(e) {
          this.$toasted.global.api_error(e)
        }
      },
    },
  }
</script>
